import React from 'react';
import PropTypes from 'prop-types';
import './LastOrder.scss';

const LastOrder = ({
  topicTitle,
  paperType,
  discipline,
  academicLevel,
  pages,
}) => (
  <div className="latest-orders__slide">
    <div className="latest-orders__slide__topic" data-label="Topic / type">{topicTitle}<span>{paperType}</span></div>
    <div className="latest-orders__slide__discipline" data-label="Discipline / level">{discipline}<span>{academicLevel}</span></div>
    <div className="latest-orders__slide__pages" data-label="Pages">{pages}</div>
    <div className="latest-orders__slide__delivered" data-label="Delivered">Today</div>
  </div>
);

LastOrder.propTypes = {
  topicTitle: PropTypes.string.isRequired,
  paperType: PropTypes.string.isRequired,
  discipline: PropTypes.string.isRequired,
  academicLevel: PropTypes.string.isRequired,
  pages: PropTypes.string.isRequired,
};

export default LastOrder;
