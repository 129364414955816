import './index.scss';

const Accordion = ({
  itemsSelector = '.Accordion__item',
  headingSelector = '.Accordion__item__heading',
  contentWrapperSelector = '.Accordion__item__body',
  contentSelector = '.Accordion__item__content',
} = {}) => {
  // eslint-disable-next-line no-undef
  const items = document.querySelectorAll(itemsSelector);
  if (!items) return;

  function ToggleAccordion(item) {
    this.element = item;
    this.heading = this.element.querySelector(headingSelector);

    this.open = () => {
      this.element.classList.add('open');
      this.element.querySelector(contentWrapperSelector).style.height = `${
        this.element.querySelector(contentSelector).offsetHeight
      }px`;
    };

    this.close = () => {
      this.element.classList.remove('open');
      this.element.querySelector(contentWrapperSelector).style.height = '0px';
    };

    this.run = () => {
      this.heading.addEventListener(
        'click',
        () => {
          if (this.element.classList.contains('open')) {
            this.close();
          } else {
            this.open();
          }
        },
        false,
      );
    };
  }

  [].slice.call(items).forEach((item) => {
    const openContent = new ToggleAccordion(item);
    openContent.run();
  });
};

export default Accordion;
