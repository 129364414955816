import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const FeaturesItem = ({
  heading,
  headingTag,
  text,
  className,
  icon,
}) => {
  const HeadingElement = `${headingTag}`;

  return (
    <div
      className={classNames(
        'features__item',
        className,
      )}
    >
      <HeadingElement
        className={
          classNames(
            'features__item__title h3',
            icon,
          )}
      >
        {heading}
      </HeadingElement>
      <p className="features__item__text">{text}</p>
    </div>
  );
};

FeaturesItem.propTypes = {
  heading: PropTypes.string.isRequired,
  headingTag: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.string,
};

FeaturesItem.defaultProps = {
  className: undefined,
  headingTag: 'h3',
  icon: '',
  text: '',
};

export default FeaturesItem;
