import React from 'react';
import Helmet from 'react-helmet';
import staticData from './index-data.json';
import SectionTop from '../components/SectionTop';
import { Section } from '../components/Section';
import { Features, FeaturesItem } from '../components/Features';
import { Calculator, CalculatorAside } from '../components/Calculator';
import Statistics from '../components/Statistics';
import { Banner, BannerButtons } from '../components/Banner';
import LazyImage from '../components/LazyImage';
import Testimonials from '../components/Testimonials';
import { Samples } from '../components/Samples';
import LastCompletedProjects from '../components/LastCompletedProjects';
import Layout from '../components/Layout/Layout';
import WeDoBlock from '../components/WeDoBlock';
import FreeTools from '../components/FreeTools';

import './index.scss';

import imgCta from '../img/img_CTA.svg';
import imgCta3 from '../img/img_cta-3.svg';
import imgCta32x from '../img/img_cta-3.2x.svg';
import SectionFaqs from '../components/SectionFaqs';

const IndexPage = () => (
  <Layout className="IndexPage">
    <Helmet>
      <title>Canada Essay Writing Service for Students of All Levels | Ca.CustomWritings.com</title>
      <meta
        name="description"
        content="Canadian essay writing service with 15+ years of experience offers 100% original custom papers, free edits, and quick delivery. Order now and get professional help with your essay."
      />
      <link rel="alternate" href="https://www.customwritings.com" hrefLang="x-default" />
      <link rel="alternate" href="https://www.customwritings.com" hrefLang="en-us" />
      <link rel="alternate" href="https://www.customwritings.com/ae" hrefLang="en-ae" />
      <link rel="alternate" href="https://ca.customwritings.com" hrefLang="en-ca" />
      <link rel="alternate" href="https://cn.customwritings.com" hrefLang="zh" />
      <link rel="canonical" href="https://ca.customwritings.com" />
    </Helmet>

    <SectionTop>
      <div className="Section-top__content__title">Academic experts ready to write you a first-class essay</div>
      <span className="Section-top__content__subtitle" />
      <span className="Section-top__content__description">We complete all kinds of papers for Canadian students. From essays to coursework and even dissertations, our writers will finish your task at a professional level.</span>
      <a rel="nofollow" href="https://www.customwritings.com/dashboard/orders/create/" className="button">Place your order</a>
    </SectionTop>

    <Section className="Section-features Section-features--top">
      <Features width="none">
        <FeaturesItem
          heading="Only professional writers"
          text="International experts in 80 disciplines and native English speakers can complete papers for any Canadian student at the highest level."
          icon="ico_superwriters"
        />
        <FeaturesItem
          heading="Ethical writing assistance "
          text="In Canada, learning from examples provided by an essay writer is as ethical as hiring a tutor to study your complex subjects better."
          icon="ico_plagiarism-free"
        />
        <FeaturesItem
          heading="24/7 help with your tasks"
          text="We can find a writer for the various tasks teachers assign in Canada; our support operators work 24/7 and deliver papers quickly."
          icon="ico_moneyback"
        />
      </Features>
    </Section>

    <Section className="Section-calculator Section--bg-blue">
      <h2 className="Section__heading">Calculate the price of your paper here</h2>
      <div className="calculator-wrapper">
        <Calculator />
        <CalculatorAside />
      </div>
    </Section>

    <Section className="Section-statistics">
      <Statistics />
    </Section>

    <Section className="Section-features">
      <h2 className="Section__heading">Advantages of our Canadian essay writing service</h2>
      <Features width="none" grid="2">
        <FeaturesItem
          heading="Zero plagiarism"
          text="To stay original, you should only buy papers from a trustworthy company. We write every essay from scratch and check it with plagiarism-detection tools to make sure it’s unique."
          icon="ico_custom-formatting"
        />
        <FeaturesItem
          heading="Short deadlines"
          text="With our Canadian service, you can ask an expert to finish your paper in as soon as 4 hours. If you are in a hurry, we can deliver your essay on the same day you order it."
          icon="ico_short-deadlines"
        />
        <FeaturesItem
          heading="Speedy delivery"
          text="We deliver over 98% of orders on time and try to keep every student happy. If you have to write your paper fast and do it at a high level, ask our professional writers to help you out."
          icon="ico_fast-delivery"
        />
        <FeaturesItem
          heading="Custom formatting"
          text="Our writers take all of your instructions into account. When using our service in Canada, you can have your essay written according to APA, MLA, Chicago, Harvard, or any other style."
          icon="ico_narrow"
        />
      </Features>
    </Section>

    <Section className="Section Section-testimonials Section--bg-blue">
      <h2 className="Section__heading">What customers say about us</h2>
      <Testimonials testimonials={staticData.testimonials} />
    </Section>

    <Section className="Section-banner">
      <Banner>
        <h2 className="Section-banner__heading Section-banner__heading--center">Get top-level help with essay writing</h2>
        <div className="Section-banner__img">
          <picture>
            <LazyImage
              src={imgCta}
            />
          </picture>
        </div>
        <BannerButtons>
          <a rel="nofollow" href="https://www.customwritings.com/dashboard/orders/create/" className="button">Place an order</a>
        </BannerButtons>
      </Banner>
    </Section>

    <Section className="Section-features Section--bg-blue">
      <h2 className="Section__heading">What else can I get when you write my essay?</h2>
      <WeDoBlock />
    </Section>

    <Section className="Section-protected">
      <div className="Section-protected__content">
        <h2 className="Section__heading">Confidential and secure help for every customer</h2>
        <p className="Section__description">You’ll always stay safe while ordering your essay from our paper writing service. We’ve taken steps to protect your information from cyber threats.</p>
        <ul className="list--check">
          <li>We store your personal data in safe clouds where it remains invulnerable to cyber-attacks.</li>
          <li>We regularly check our website for weak points and reinforce it with the newest security tools.</li>
          <li>We keep your cooperation with our expert writers confidential. Check out our privacy policy to find out more.</li>
        </ul>
      </div>
      <div className="Section-protected__services">
        <a className="Section-protected__services__truste" href="//privacy.truste.com/privacy-seal/validation?rid=91021013-5f4c-4d13-8123-c5d380903cb6" target="_blank" rel="nofollow noopener noreferrer">
          <LazyImage src="/images/ico_truste.svg" alt="TRUSTe" />
        </a>
        <a rel="nofollow noopener noreferrer" href="https://pci.usd.de/compliance/8032-19B0-4203-FCDE-3C15-2D0B/details_en.html" target="_blank"><LazyImage src="https://pci.usd.de/compliance/8032-19B0-4203-FCDE-3C15-2D0B/seal_65.png" /></a>
      </div>
    </Section>

    <Section className="Section-latest-orders">
      <div className="latest-order-wrapper">
        <h2 className="Section__heading">Check out a few orders we’ve finished recently</h2>
        <LastCompletedProjects />
      </div>
    </Section>

    <Section className="Section-tools Section--bg-blue">
      <h2 className="Section__heading">Use our free essay writing tools to improve your papers</h2>
      <FreeTools />
    </Section>

    <Section className="Section-samples">
      <div className="latest-order-wrapper">
        <h2 className="Section__heading">Samples of our works</h2>
        <div className="samples-slider-container">
          <svg xmlns="http://www.w3.org/2000/svg" width="92" height="112" fill="none">
            <path fill="#D6F0F0" d="M37.26 53.3c16.5 3.05 25.42 27.33 13.93 41.9-10.47 13.37-33.1 11.53-37.57-10.02C8.2 58.4 28.2 51.62 37.26 53.3zM85.07 5.32c-1.5-1.4-3.63-1.98-5.8-2-8.8.17-11.37 13-3.33 16.3 8.46 3.48 15.92-8.4 9.13-14.3z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" width="88" height="88" fill="none">
            <path fill="#ABDFDF" d="M43.72 9.8c16.82-4.16 41.2 6.82 36.98 25.82-3.8 17.4-30.93 33.94-44.8 15.1C18.8 27.2 34.5 12.06 43.73 9.8z" />
            <path fill="#BBE5E5" d="M10.77 86.37c2.32 0 4.52-1.17 6.3-2.84 6.96-6.94-1.26-19.58-10.4-16.1-9.64 3.66-6.12 19.3 4.1 18.94z" />
          </svg>
          <Samples samples={staticData.samples} />
        </div>
      </div>
    </Section>

    <Section className="Section-seotext">
      <div className="Section-seotext__row">
        <h1 className="h3">Use our essay writing service Canada to improve your academic score</h1>
        <div className="Section-seotext__cell">
          <p>
            All learners face certain complications during their college and university years, and Canadian students are
            no exception. Many of them are ready to scream, “somebody, please, write my essay for me!” They often
            struggle with essay writing, probably the most common kind of assignment in Canadian schools, and may fail
            due to different reasons. Some have poor writing skills, a bad command of some disciplines, personal
            problems, etc. Others simply lack time because they are already busy with so many tasks and duties. That is
            why the idea of using an essay writing service is so popular among Canadian students. Our essay writing
            platform is a trusted website that students recommend to their friends, so if you study in Canada, use the
            assistance of our professional writers to upgrade your papers to the desired level.
          </p>
        </div>
        <div className="Section-seotext__cell">
          <p>
            We are Ca.CustomWritings.com, and we are famous all over the globe. <strong>Our main task is to help students write the papers assigned to them, regardless of how difficult they are. We’ve hired more than 300 writers to take care of any requests you may have.</strong> Our experts work to meet all of your requirements and deliver your paper asap. Using our Canadian essay
            writing service, you’ll receive the help of experienced writers and editors. No matter what causes your
            academic complications, we’ll find the most effective solution to your problem.
          </p>
        </div>
      </div>
      <div className="Section-seotext__row">
        <h2>Enjoy the best essay writing services in Canada</h2>
        <div className="Section-seotext__cell">
          <p>
            Ca.CustomWritings.com is not a newbie in the sphere of academic essay writing. We have been working for many
            years to help Canadian students overcome the most difficult hardships associated with their scholastic
            lives. Writing an essay doesn’t seem to be overly complex at first glance. Nonetheless, there are a great
            many paper types with certain peculiarities and differences, and students may find them too difficult to
            manage. Fortunately, our Canada essay writing service is always ready to provide professional assistance.
          </p>
          <p>
            Rest assured that you’ll receive the best essay writing services because we only have first-class
            specialists on our team. We are selective, and attentively check all incoming candidates for the post of
            writer on our website. We take time to verify their diplomas and any certificates related to their work
            experience. They must also pass a job interview and a few writing tests to confirm their qualifications.
            Afterward, an admission committee carries out a decision. We trust our admission committee because it
            consists of the most proficient specialists we have. Accordingly, our customers can count on us to receive
            high-quality assignments. Rely on our Canadian essay writers and enjoy professional assistance whenever you
            need it.
          </p>
          <p>
            Ca.CustomWritings.com is a place where you can find hundreds of certified and skilled writers. They
            constantly expand their knowledge and improve their skills so that students in Canada can get academic
            papers of the desired quality. As our working team is quite large, your choice is rich. You can count on:
          </p>
          <ul>
            <li>Writing and rewriting</li>
            <li>Editing and proofreading</li>
            <li>Searching for information</li>
            <li>Crafting outlines</li>
            <li>Creating thesis statements</li>
            <li>Enhancing readability</li>
            <li>Inserting quotations</li>
            <li>Preparing reference lists, etc.</li>
          </ul>
          <p>
            Although we specialize in essay writing, you’re welcome to request other academic services as well. Among
            such are dissertations, laboratory reports, case studies, research proposals, term papers, PowerPoint
            presentations, book reviews/reports, resumes, speeches, and other projects. Our writers will tackle them
            exactly as you want because we treat every order individually. We will assign you the most suitable
            specialist who can meet your requirements. That’s how we provide top-level help with writing an essay ASAP.
          </p>
        </div>
        <div className="Section-seotext__cell">
          <p>
            With our service, there’s no need for you to select a personal assistant on your own. Rest assured, we’ll
            pick the best-fitting writer for your task. With plenty of specialists offering essay writing help on our
            website, we always choose an available writer with the necessary qualifications. We work with
            native-speaking Canadian writers and specialists who know Canadian English as their second language. Thanks
            to the presence of so many specialists, you may likewise count on receiving aid in whatever academic
            discipline you require.
          </p>
          <p>Among such spheres are:</p>
          <ul>
            <li>Literature</li>
            <li>History</li>
            <li>Culture</li>
            <li>Mathematics</li>
            <li>Finance</li>
            <li>Algebra</li>
            <li>Geometry</li>
            <li>Physics</li>
            <li>Chemistry</li>
            <li>Computer science</li>
            <li>Marketing</li>
            <li>Engineering</li>
            <li>Economics</li>
            <li>Business, etc.</li>
          </ul>
          <p>
            However, we don’t limit your choice to just these. If you feel that some assignments exceed your
            capabilities, use our Canadian essay writing service. We won’t disappoint you, and will write any paper you
            need to help you meet your academic objectives.
          </p>
        </div>
      </div>

      <div className="Section-seotext__row">
        <h2>Get affordable custom essay writing and pleasant discounts</h2>
        <p>
          The matter of price may be painful for many students. They don’t commonly earn plenty of money and therefore
          cannot afford expensive online writing assistance. However, you should not grow desperate, since top essay
          writing doesn’t obligatorily mean something overly expensive. Our custom platform offers relatively low
          prices, and while professional writing services must be fairly paid for, they’re quite affordable when you use
          Ca.CustomWritings.com. Whether you need a regular paper, a research project, or a college essay, we’re always
          here to assist.
        </p>
        <p>
          The price per page starts at $13, and it grows if you have a complex discipline or an urgent deadline.
          Everything depends on the requirements you set. When you fill out the application form, pay attention to the
          compulsory fields. They are:
        </p>
        <ul>
          <li>Academic level (high school, college, university)</li>
          <li>Type (essay, coursework, dissertation, etc.)</li>
          <li>Deadline (measured in hours, days, or weeks)</li>
          <li>Length (measured in words or pages)</li>
          <li>Personal assistant (native CA speaker or non-native)</li>
        </ul>
        <p>
          All these requirements affect the total cost. For example, assignments for high school are cheaper than those
          for university, a standard essay is cheaper than a dissertation, and so on. If you find your paper too
          expensive, select a longer deadline or choose the Best available category of writer. Just spend a couple of
          minutes to figure out how it works and adjust the cost until it fully suits your budget.
        </p>
        <p>
          We have a few extra services that can improve your paper even further. You can see the price for each extra
          service in the order form. Using our smart assistance, you always receive exactly what you want and don’t
          overpay.
        </p>
      </div>
      <div className="Section-seotext__row">
        <h2>Canada essay writing service and its additional advantages</h2>
        <p>
          Our Canadian essay writing service continuously tries to satisfy all the demands of busy students. We provide
          multiple academic features and sell them at an affordable price. At the same time, students require more
          conditions and warranties. Therefore, Ca.CustomWritings.com is happy to ensure the following advantages:
        </p>
        <ul className="list">
          <li><strong>Urgent assistance</strong> <br /> Time is one of the worst enemies of nearly every student. They
            have to constantly compete with strict deadlines, which mustn’t be violated. Of course, you may try an essay
            writing app. It helps to quickly check your grammar and offers some smart writing and editing prompts.
            Nevertheless, you should spend some time thinking those prompts over and deciding when, where, and how to
            use them. That’s why hiring a personal assistant is always better. <br />
            Our expert writers work fast and productively. They understand what technique and strategy they should use
            to complete your paper on time. Their successful experience allows them to beat the deadlines that seem
            hopeless to most students. We’ll do our best to deliver your urgent orders on time when you need them so
            badly.
          </li>
          <li>
            <strong>Only original content</strong> <br />
            One of the compulsory demands of academic writing is making it authentic and creative. Our specialists can
            easily satisfy this demand. They know how to cover the most difficult topics in any discipline to make your
            essay readable, informative, convincing, and impressive. We work to make you fully satisfied with the
            originality of our papers. To make sure our content is fully unique, we’ve implemented a dependable checker,
            which detects the slightest signs of plagiarism. If such elements are detected, they will be instantly
            deleted from your paper.
          </li>
          <li>
            <strong>Full confidentiality</strong> <br />
            Many online users are afraid that their private data will be stolen and misused by dishonest people. We
            clearly understand how dangerous this may be. Nobody wants to expose his/her data to anybody else.
            Therefore, we use the most efficacious antivirus software. It’s regularly updated to handle even newly
            created viruses and malicious programs. Of course, we pledge to never share any data about our customers
            with anyone outside of our company.
          </li>
          <li>
            <strong>A money-back option</strong> <br />
            We make sure you don’t pay for anything when using our services. After one of our writers accepts your
            conditions, he/she pledges to complete them all. If for any reason your paper turns out unsatisfactorily,
            has a weak thesis statement, contains mistakes, is delayed, etc., you can use free edits or request your
            money back, and we’ll return the funds you’ve paid to us.
          </li>
          <li>
            <strong>24/7 customer support</strong> <br />
            A need may arise when you least expect it. Many students may receive unexpected assignments or remember them
            at the last moment. That’s why the function of essay writing online is to stay available 24 hours a day. You
            can ask any question about your order anytime, and we will answer it asap. Our competent support consultants
            provide quick and informative answers to all students who need them.
          </li>
        </ul>
        <p>
          As you can see, Ca.CustomWritings.com has everything a student in Canada may require to enjoy academic success
          and feel confident. Our extra features, guarantees, and benefits are aimed at the satisfaction of our
          customers. Collaborating with our online essay writing platform, you can accomplish academic progress much
          faster than you expect. Therefore, don’t wait up; place an order straight away!
        </p>
      </div>
    </Section>

    <SectionFaqs />

    <Section className="Section-banner Section--bg-blue Section-banner--competent-writers">
      <Banner>
        <h2 className="Section-banner__heading Section-banner__heading--center">Get help from a pro <span>essay writer</span> in Canada</h2>
        <div className="Section-banner__img">
          <picture>
            <source media="(min-width: 768px)" srcSet={imgCta32x} type="image/png" />
            <img src={imgCta3} alt="" />
          </picture>
        </div>
        <BannerButtons>
          <a rel="nofollow" href="https://www.customwritings.com/dashboard/orders/create/" className="button">Place an order</a>
        </BannerButtons>
      </Banner>
    </Section>
  </Layout>
);

export default IndexPage;
