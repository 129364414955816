import React from 'react';
import './index.scss';

const FreeTools = () => (
  <div className="tools">
    <a
      className="btn plagiarism"
      href="https://www.customwritings.com/check-paper-for-plagiarism.html"
      rel="nofollow"
    >
      Free plagiarism checker
    </a>
    <a
      className="btn citation-generator"
      href="https://www.customwritings.com/howtowrite/citation-generator/"
      rel="nofollow"
    >
      Bibliography generator
    </a>
    <a
      className="btn pages-calculation"
      href="https://www.customwritings.com/howtowrite/pages-calculation/"
      rel="nofollow"
    >
      Word to pages converter
    </a>
  </div>
);

export default FreeTools;
