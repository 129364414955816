import React, { Component, createRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import SampleCard from './SampleCard';
import './Samples.scss';

const SplideComponent = typeof window !== 'undefined' ? require('@splidejs/react-splide') : null;

const options = {
  perPage: 4,
  type: 'loop',
  gap: '1.5rem',
  arrows: false,
  updateOnMove: true,
  breakpoints: {
    768: {
      perPage: 1,
      gap: '3.5rem',
      focus: 'center',
      autoWidth: true,
    },
    1024: {
      perPage: 2,
      gap: '2.5rem',
    },
    1400: {
      perPage: 3,
      gap: '2.5rem',
    },
  },
};

class Samples extends Component {
  constructor(props) {
    super(props);

    this.slider = createRef();
  }

  moveSlider = (direction) => {
    this.slider.current.splide.go(direction);
  };

  render() {
    if (!SplideComponent) return null;

    const {
      Splide,
      SplideSlide,
    } = SplideComponent;

    const { samples } = this.props;

    return (
      <Fragment>
        <button
          type="button"
          className="splide__arrow splide__arrow--prev samples-slider__arrow-prev"
          onClick={() => this.moveSlider('<')}
        />
        <button
          type="button"
          className="splide__arrow splide__arrow--next samples-slider__arrow-next"
          onClick={() => this.moveSlider('>')}
        />
        <Splide options={options} className="samples-slider" ref={this.slider}>
          {
            [].slice.call(samples)
              .map(sample => (
                <SplideSlide className="Sample" key={sample.id}>
                  <SampleCard {...sample} />
                </SplideSlide>
              ))
          }
        </Splide>
      </Fragment>
    );
  }
}

Samples.propTypes = {
  samples: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]).isRequired,
};

export default Samples;
