import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Features.scss';

const Features = ({ children, grid, width }) => (
  <div className={classNames('features', `features--grid-${grid}`, `features--width-${width}`)}>
    {children}
  </div>
);

Features.propTypes = {
  children: PropTypes.node,
  grid: PropTypes.string,
  width: PropTypes.string,
};

Features.defaultProps = {
  children: undefined,
  grid: '3',
  width: '960',
};

export default Features;
