// @todo enable next eslint rules
/* eslint-disable */
import React, { Component, Fragment } from 'react';
import classNames from 'classnames';
import Spinner from '../Spinner';
import './LastCompletedProjects.scss';
import LastOrder from './LastOrder';

const SplideComponent = typeof window !== 'undefined' ? require('@splidejs/react-splide') : null;

const options = {
  perPage: 1,
  type: 'loop',
  gap: '0.5rem',
  arrows: false,
  autoHeight: true,
};

class LastCompletedProjects extends Component {
  state = {
    lcpLoaded: false,
    error: false,
    items: [],
    currentPage: 1,
    itemsPerPage: 5,
    limit: 15,
  };

  componentDidMount() {
    function getScript(url, callback) {
      const script = document.createElement('script');
      script.src = url;
      script.async = true;
      script.onload = callback;
      document.querySelector('[data-lcp]').appendChild(script);
    }

    if (!document.querySelector('script[src="/assets/js/lcp.js"]')) {
      getScript('/assets/js/lcp.js', () => { this.getLcp(); });
    }

    if (window.innerWidth <= 768) this.setState({ itemsPerPage: 2 });
  }

  getLcp = () => {
    const _this = this;
    const { limit } = this.state;

    new LCP({
      ID: '0',
      format: 'json',
      limit,
      fields: [
        'orderId',
        'topicTitle',
        'discipline',
        'paperType',
        'academicLevel',
        'pages',
      ],
      callback() {
        _this.setState({
          lcpLoaded: true,
          items: this.options.json,
        });
      },
    });
  };

  renderItems = () => {
    const {
      SplideSlide,
    } = SplideComponent;

    const {
      items,
      itemsPerPage,
      limit,
    } = this.state;

    const currentItems = [...items];
    const pages = (Math.floor(limit / itemsPerPage) * itemsPerPage) / itemsPerPage;

    return [...Array(pages).keys()].map((index) => {
      return (
        <SplideSlide key={index}>
          {
            currentItems.splice(0, itemsPerPage).map((item, index) => {
              return (
                <LastOrder
                  key={index}
                  topicTitle={item.topicTitle}
                  paperType={item.paperType}
                  discipline={item.discipline}
                  academicLevel={item.academicLevel}
                  pages={item.pages}
                />
              );
            })
          }
        </SplideSlide>
      )
    })
  };

  render() {
    if (!SplideComponent) return null;

    const {
      Splide,
    } = SplideComponent;

    const { lcpLoaded } = this.state;

    return (
      <div
        className={classNames(
          'latest-orders-slider-wrap',
          !lcpLoaded && 'loading',
          lcpLoaded && 'loaded',
        )}
        data-lcp
      >
        {lcpLoaded && (
          <Fragment>
            <div className="latest-orders__slide latest-orders__head">
              <div className="latest-orders__slide__topic">Topic / type</div>
              <div className="latest-orders__slide__discipline">Discipline / level</div>
              <div className="latest-orders__slide__pages">Pages</div>
              <div className="latest-orders__slide__delivered">Delivered</div>
            </div>
            <Splide options={options} className="latest-orders-slider">
              {
                this.renderItems()
              }
            </Splide>
          </Fragment>
        )}
        {!lcpLoaded && <Spinner />}
      </div>
    );
  }
}

export default LastCompletedProjects;
