import React, { useEffect } from 'react';
import { Section, SectionHeading } from '../Section';
import Accordion from '../Accordion';
import faqsHome from './faqs.json';
import './style.scss';

const SectionFaqs = () => {
  useEffect(() => {
    if (typeof document !== 'undefined') {
      // eslint-disable-next-line no-new
      new Accordion({});
    }
  }, []);

  return (
    <Section className="section-accordion-aside section-accordion-aside--without-padding-top">
      <div className="section-accordion-aside__inner">
        <SectionHeading heading="Frequently asked questions" />
        <div className="Accordion section-accordion-aside__accordion">
          {faqsHome.home.map((faq, index) => (
            <div className={`Accordion__item ${index === 0 ? 'open' : ''}`} itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
              <h3 className="Accordion__item__heading" itemProp="name">
                {faq.title} <span className="Accordion__item__close" />
              </h3>
              <div className="Accordion__item__body" style={{ height: index === 0 ? 'unset' : 0 }}>
                <div className="Accordion__item__content" itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                  <div className="calculate-height" itemProp="text">
                    {faq.text}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Section>
  );
};

export default SectionFaqs;
