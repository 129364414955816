/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withPrefix } from 'gatsby';
import './SampleCard.scss';

const SampleCard = ({
  sampleTitle,
  pdfLink,
  paperTitle,
  academicLevel,
  discipline,
  paperFormat,
}) => (
  <Fragment>
    <div className="Sample__type">{sampleTitle}</div>
    <div className="Sample__title" data-label="Paper title">{paperTitle}</div>
    <div className="Sample__level" data-label="Academic level">{academicLevel}</div>
    <div className="Sample__discipline" data-label="Discipline">{discipline}</div>
    <div className="Sample__format" data-label="Paper Format">{paperFormat}</div>
    <a className="button button--white" href={withPrefix(pdfLink)}>View this sample</a>
  </Fragment>
);

SampleCard.propTypes = {
  sampleTitle: PropTypes.string.isRequired,
  pdfLink: PropTypes.string.isRequired,
  paperTitle: PropTypes.string.isRequired,
  academicLevel: PropTypes.string.isRequired,
  discipline: PropTypes.string.isRequired,
  paperFormat: PropTypes.string.isRequired,
};

export default SampleCard;
