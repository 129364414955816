import React, { Fragment } from 'react';
import './index.scss';
import Slider from '../Slider';

const options = {
  type: 'loop',
  gap: '3rem',
  autoWidth: true,
  focus: 'center',
  arrows: false,
  autoplay: true,
  pagination: false,
  breakpoints: {
    1024: {
      gap: '1.5rem',
    },
  },
};

const Statistics = () => (
  <div className="stats-slider">
    <Slider
      options={options}
      className="stats-list"
    >
      <Fragment key="stats-list__item splide__slide1">
        <span className="stats-list__item__value">98.42%</span> orders
        delivered before the deadline
      </Fragment>
      <Fragment key="stats-list__item splide__slide2">
        <span className="stats-list__item__value">8.5 <span>/ 10</span></span>
        average quality score from customers
      </Fragment>
      <Fragment key="stats-list__item splide__slide3">
        <span className="stats-list__item__value">10+</span> years in academic writing
      </Fragment>
      <Fragment key="stats-list__item splide__slide4">
        <span className="stats-list__item__value">399</span>
        writers active
      </Fragment>
    </Slider>
  </div>
);

export default Statistics;
