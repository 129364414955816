import React from 'react';
import PropTypes from 'prop-types';
import './_index.scss';

const WeDoBlock = ({ listItems }) => (
  <div className="wedo-list">
    <div className="wedo-list__item ico_custom-formatting">{listItems[0]}</div>
    <div className="wedo-list__item ico_freerevisions">{listItems[1]}</div>
    <div className="wedo-list__item ico_flexible-pricing">{listItems[2]}</div>
    <div className="wedo-list__item ico_deadlines">{listItems[3]}</div>
    <div className="wedo-list__item ico_exp_writers">{listItems[4]}</div>
    <div className="wedo-list__item ico_plagiarism-free">{listItems[5]}</div>
    <div className="wedo-list__item ico_flexibility">{listItems[6]}</div>
    <div className="wedo-list__item ico_samples2">{listItems[7]}</div>
  </div>
);

WeDoBlock.propTypes = {
  listItems: PropTypes.shape(PropTypes.array),
};

WeDoBlock.defaultProps = {
  listItems: [
    'Attention to detail',
    'Free revisions',
    'Flexible prices',
    'Fast support service',
    'Direct communication with the writer',
    'Plagiarism check',
    'Delivery in parts',
    'Explanations of and comments on your paper',
  ],
};

export default WeDoBlock;
